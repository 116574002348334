var site = site || {};
var generic = generic || {};

(function ($) {
  $(document).on('email_signup:success_but_signed_up', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-signed-up');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  $(document).on('email_signup:success_and_new', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow('.js-popup-offer-thank-you');
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  $(document).on('email_signup:launch', function () {
    Drupal.behaviors.popupOfferV1.launchPopupWindow();
    Drupal.behaviors.popupOfferV1.overwriteColorboxClose();
    Drupal.behaviors.popupOfferV1.bindEvents();
  });

  Drupal.behaviors.popupOfferV1 = {
  // Inline template name that is grabbed and shown in pop-up.
    templateName: 'popup_offer_au_v2',

    // Class name to be used for colorbox popup launch.
    colorBoxClassName: 'js-popup-offer-overlay-cbox',

    // Cookie name used to decide to show popup or not.
    cookieName: 'hide_popup_offer',

    // The four larger elements that are displayed at different
    // stages of the popup process.
    popupElements: {
      first: '.js-popup-offer-outer-wrap',
      thankYou: '.js-popup-offer-thank-you',
      signedUp: '.js-popup-offer-signed-up',
      terms: '.js-popup-offer-terms'
    },

    // Track whether or not the .js-popup-offer-terms element is showing.
    termsShowing: false,

    // Store which div class was showing when the terms were opened.
    elementShowing: '',

    // Store the original colorbox close method.
    originalColorboxCloseMethod: $.colorbox.close,

    // Given the way the terms & conditions are shown in the colorbox overlay,
    // the close function must be overwritten a bit:
    // If the flag for termsShowing is true, just hide the terms, don't close the box.
    // Otherwise, act normally, by calling the original close method.
    overwriteColorboxClose: function () {
      var self = this;

      $.colorbox.close = function () {
        if (self.termsShowing) {
          self.hideTerms();
        } else {
          self.originalColorboxCloseMethod();
        }
      };
    },

    // Show the terms element and store what was being shown previously.
    showTerms: function () {
      var self = this;

      $(self.popupElements.terms).show();
      $(self.elementShowing).hide();
      self.termsShowing = true;
    },

    // Hide the .js-popup-offer-terms element and show previous element.
    hideTerms: function () {
      var self = this;

      $(self.popupElements.terms).hide();
      $(self.elementShowing).show();
      self.termsShowing = false;
    },

    // Make the RPC call for the email sign-up form in the pop-up.
    processForm: function () {
      var self = this;
      // Get all the fields in code because 'serialize' encodes bad emails and doesn't decode plus symbols.
      var formFields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'PC_EMAIL_ADDRESS'
      ];
      // Create an object to hold all the parameters and their values.
      var paramObj = {};

      // Loop through each field and get its value.
      $.each(formFields, function (index, value) {
        var formField = $('.js-popup-offer-sign-up input[name=' + value + ']');

        paramObj[value] = formField.val();
      });

      // Send the data via a JSON RPC call.
      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getData();
          // Store returned boolean value for whether the user has already opted in the promotion before.
          var previouslyOptedInEmailPromotion = response.userinfo.previously_opted_in_email_promotion;
          // Store return boolean for still running the special offer codes.
          var specialOfferCodeStatus = response.userinfo.special_offer_code_status || 1;

          // If the user had not previously opted in AND the special offers are still running.
          if (!previouslyOptedInEmailPromotion && specialOfferCodeStatus) {
            $(self.popupElements.thankYou).show();
            self.elementShowing = self.popupElements.thankYou;
            // Else show that the user has already signed up for this offer.
          } else {
            $(self.popupElements.signedUp).show();
            self.elementShowing = self.popupElements.signedUp;
          }

          // Regardless, hide the original sign up form portion.
          $(self.popupElements.first).hide();
        },
        onFailure: function (jsonRpcResponse) {
        // Store messages and display them in error div element.
          var messages = jsonRpcResponse.getMessages();

          $('.js-popup-offer-error-messages').empty().html(messages[0].text).removeClass('hidden');
        }
      });
    },

    // Launch the popup window with the appropriate element visible.
    launchPopupWindow: function (elementToShow) {
      var self = this;
      var okayToLaunchPopup = true;
      var isMobile = site.client.isMobile;
      var template_name = 'inside_popup_offer_au';
      var template = site.templates[template_name];
      var templateData = '';
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });

      if (isMobile && !_.isUndefined(template)) {
        templateData = site.template.get({
          name: template_name
        });
      }
      // Get value of data attribute 'enable-popup', it's should be either 'yes' or 'no'.
      var $templateContext = $(templateHTML);
      var enablePopup = $templateContext.data('enable-popup');

      if (typeof enablePopup === 'undefined') {
        enablePopup = 'yes';
      } // If not set, give it the value of 'yes'.

      // Set a default elementToShow just in case.
      if (elementToShow === undefined) {
        elementToShow = self.popupElements.first;
        // If there was no elementToShow parameter, then this was triggered by visiting some page, not triggered by an email signup.
        // Therefore, the value of enablePopup must be used to see if the popup should actually launch.
        if (enablePopup === 'no' || enablePopup === 'false' || !enablePopup) {
          okayToLaunchPopup = false;
        }
      }

      if (okayToLaunchPopup) {
      // Launch the popup with colorbox.
        if (isMobile && !_.isUndefined(template)) {
          generic.overlay.launch({
            content: templateHTML,
            width: 511,
            height: 352,
            cssClass: self.colorBoxClassName,
            onClosed: function () {
              generic.overlay.launch({
                content: templateData,
                width: 511,
                height: 352,
                cssClass: self.colorBoxClassName
              });
            }
          });
        } else {
          generic.overlay.launch({
            content: templateHTML,
            width: 511,
            height: 352,
            cssClass: self.colorBoxClassName
          });
        }
        // Loop through all popupElements and only show the one specified
        // in the parameter elementToShow (or the default value).
        for (var elemKey in self.popupElements) {
          if (self.popupElements.hasOwnProperty(elemKey)) {
            var elemValue = self.popupElements[elemKey];

            if (elemValue === elementToShow) {
              $(elemValue).show();
              self.elementShowing = elemValue;
            } else {
              $(elemValue).hide();
            }
          }
        }
      }
    },

    bindEvents: function () {
      var self = this;

      $('.js-popup-offer-footer a').click(function (e) {
        e.preventDefault();
        self.showTerms();
      });
      $('.js-popup-offer-submit').click(function (e) {
        e.preventDefault();
        self.processForm();
      });
    },

    attach: function () {
      var self = this;
      var offerKey = 'yes'; // This offerKey value may be of use down the road, for now it is just "yes".
      // Check for a value for the session cookie.
      var hidePopupCookie = $.cookie(self.cookieName);

      // Create session cookie with value.
      $.cookie(self.cookieName, offerKey, {
        expires: 365,
        path: '/'
      });

      // If the session cookie does not exist, work on launching the popup.
      // And the popup is set to active in Drupal
      if (hidePopupCookie !== offerKey) {
      // If offer key is set with a value other than "yes", check on its timing on the Perlgem side.
        if (offerKey !== 'yes') {
          generic.jsonrpc.fetch({
            method: 'offer',
            params: [offerKey],
            onSuccess: function (r) {
              var offer = r.getValue();
              var isActive = offer && (offer.timing === 'active');

              // If active, then show.
              if (isActive) {
                self.launchPopupWindow();
                self.overwriteColorboxClose();
                self.bindEvents();
              }
            },
            onFailure: function () {
            // Check to see if PG server is running?
            }
          });
        } else { // Else offer key is unimportant and the offer should just popup as normal.
          self.launchPopupWindow();
          self.overwriteColorboxClose();
          self.bindEvents();
        }
      }
    // Else the session cookie does exist, so the popup should not launch.
    }
  };
})(jQuery);
